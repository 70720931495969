import { FC } from 'react';
import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
    faAward,
    faCircleNotch,
    faGamepad,
    faHandHoldingUsd,
    faSiren, faTrophy,
    faUserShield
} from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {faBullhorn, faLevelUp} from '@fortawesome/pro-duotone-svg-icons';
import { Link } from 'react-router-dom';
import {PlayerNotificationData} from "../../types.client.mongo";
import moment from "moment";

interface IProps extends PlayerNotificationData {
    sizeBig?: boolean;
}

const Notification: FC<IProps> = (props) => {
    const {
        type,
        location,
        read,
        message,
        created,
    } = props;
    let icon: IconDefinition = faCircleNotch;
    let color = 'text-gray-300';

    switch (type) {
        case 'police':
            icon = faSiren;
            color = 'text-red-500';
            break;
        case 'account':
            icon = faUserShield;
            color = 'text-orange-500';
            break;
        case 'achievement':
            icon = faAward;
            color = 'text-green-500';
            break;
        case 'level':
            icon = faLevelUp;
            color = 'text-blue-300';
            break;
        case 'news':
            icon = faBullhorn;
            color = 'text-yellow-500';
            break;
        case 'reward':
            icon = faHandHoldingUsd;
            color = 'text-blue-400';
            break;
        case 'tournament':
            icon = faTrophy;
            color = 'text-yellow-400';
            break;
        case 'game':
            icon = faGamepad;
            break;
    }

    if (location && location !== '')
        return (
            <Link
                to={location}
                className={`flex py-1 ${!read ? 'bg-white bg-opacity-5' : 'bg-transparent' } hover:bg-white hover:bg-opacity-5 transition ease-in-out duration-300`}
            >
                <div className="w-1/5 border-center my-auto">
                    <FontAwesomeIcon className={`${color} border-2xl`} icon={icon} />
                </div>
                <div className="w-4/5">
                    <div className="border-white font-semibold uppercase border-sm break-normal">{message}</div>
                    <div className="border-gray-500 font-semibold uppercase border-xxs">{moment.unix(created).fromNow()}</div>
                </div>
            </Link>
        );
    else
        return (
            <div
                className={`flex py-2 text-white ${!read ? 'bg-white bg-opacity-5' : 'bg-transparent' } hover:bg-white hover:bg-opacity-5 transition ease-in-out duration-300`}
            >
                <div className="w-16 my-auto text-center">
                    <FontAwesomeIcon className={`${color} text-2xl`} icon={icon} />
                </div>
                <div className="w-full pr-2">
                    <div className="text-white font-semibold uppercase text-xs pb-1 break-normal">{message}</div>
                    <div className="text-gray-500 font-semibold uppercase text-xxs">{moment.unix(created).fromNow()}</div>
                </div>
            </div>
        );
}

export default Notification;