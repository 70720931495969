import { FC, Suspense } from 'react';
import { BrowserRouter } from 'react-router-dom';
import ReactGA from 'react-ga';
import ReactGA4 from 'react-ga4';
import CookieConsent from 'react-cookie-consent';
import Router from './Router';
import Levelbar from "./components/Navbar/Levelbar";
import LoadingScreen from "./components/Uncategorized/LoadingScreen";
import {ToastContainer} from "react-toastify";
import {ChatProvider} from "./contexts/Chat.context";
import {PlayerProvider} from "./contexts/Player.context";

ReactGA.initialize('UA-169806672-01');
ReactGA.pageview(window.location.pathname + window.location.search);
ReactGA4.initialize('G-C9XCQ04FCC');
ReactGA4.send({ hitType: "pageview", page: window.location.pathname + window.location.search });

// -mt-2 translate-y-64 bg-red-400 bg-yellow-400 font-sans font-mono bg-red-600 bg-blue-600 bg-opacity-20 bg-opacity-10 border-red-400 border-blue-400 bg-gray-800 bg-opacity-20 border-orange-400

export const App: FC = () => {

  return (
      <Suspense fallback={<LoadingScreen />}>
          <ToastContainer />
          <BrowserRouter>
            <PlayerProvider>
              <ChatProvider>
                  <Levelbar />
                  <div>
                      <Router />
                      <CookieConsent
                          style={{ background: '#000' }}
                          contentStyle={{ margin: '7px' }}
                          buttonStyle={{
                              background: '#FBBF24',
                              color: '#000',
                              fontSize: '14px',
                              margin: '7px',
                          }}
                      >
                          This website uses cookies to enhance the user experience.
                      </CookieConsent>
                  </div>
              </ChatProvider>
            </PlayerProvider>
          </BrowserRouter>
      </Suspense>
  );
};
