import { FC } from 'react';
import Skeleton from "react-loading-skeleton";
import { withTranslation, WithTranslation } from 'react-i18next';
import GameTimer from "./countdown/GameTimer";
import {SocketMatchData, SocketMatchGameData} from "../../types.client.socket";

interface IProps extends WithTranslation {
  matchData: SocketMatchData;
  gameData?: SocketMatchGameData;
  roundsTotal: number;
  totalPlayers: number;
  isSpectate?: boolean;
}

const MatchMode: FC<IProps> = (props) => {
    const { matchData, gameData, isSpectate, totalPlayers, roundsTotal } = props;
    let flagName = '';

    if (matchData) {
      const flagInt = matchData.flagId;
      switch (flagInt) {
        case 0:
          flagName = 'Public';
          break;
        case 1:
          flagName = 'Ladder';
          break;
        case 2:
          flagName = 'Custom';
          break;
        case 3:
          flagName = 'Ranked';
          break;
        default:
          flagName = 'Public';
          break;
      }
    }

    return (
      <>
        {(!matchData || !matchData.modeData) ? (
          <>
            <div className={"flex flex-wrap"}>
              <div className={"w-32 md:w-64 mr-auto"}>
                <Skeleton height={7} />
              </div>
              <div className={"w-32 md:w-64"}>
                <Skeleton height={7} />
              </div>
            </div>
            <div className={"flex flex-wrap"}>
              <div className={"w-16 md:w-32 mr-auto"}>
                <Skeleton height={5} />
              </div>
              <div className={"w-16 md:w-32"}>
                <Skeleton height={5} />
              </div>
            </div>
          </>
        ) : matchData.modeData && matchData.modeData.modeConfig.ROUND_LIMIT === 0 ? (
          <div className="flex flex-wrap mb-4">
            <div className={`w-full ${isSpectate ? 'md:w-1/3' : 'md:w-1/2'} text-center md:text-left my-auto`}>
              <div className="text-xl text-white uppercase font-semibold tracking-wider">{matchData.modeData.modeName}</div>
              <div className="text-xs text-gray-500 uppercase font-semibold tracking-wider">{flagName}</div>
            </div>
            {isSpectate && gameData && gameData.timer && (
                <div className={"w-full md:w-1/3 my-auto text-center"}>
                  {gameData.countdown <= 0 && (
                      <div className={"text-white text-xl font-semibold tracking-wider"}>
                        <div className={"text-gray-400 text-xl font-semibold tracking-wider"}>
                          <GameTimer timer={gameData.timer} />
                        </div>
                      </div>
                  )}
                </div>
            )}
            <div className={`w-full ${isSpectate ? 'md:w-1/3' : 'md:w-1/2'} text-center md:text-right my-auto`}>
              <div className="text-xl text-orange-400 uppercase font-semibold tracking-wider">
                {totalPlayers.toLocaleString()} player{totalPlayers !== 1 && 's'}
              </div>
            </div>
          </div>
        ) : (
          <div className="flex flex-wrap mb-4">
            <div className={`w-full ${isSpectate ? 'md:w-1/3' : 'md:w-1/2'} text-center md:text-left my-auto`}>
              <div className="text-xl text-white uppercase font-semibold tracking-wider">{matchData.modeData.modeName}</div>
              <div className="text-xs text-gray-500 uppercase font-semibold tracking-wider">{flagName}</div>
            </div>
            {isSpectate && gameData && gameData.timer && (
              <div className={"w-full md:w-1/3 my-auto text-center"}>
                {gameData.countdown <= 0 && (
                    <div className={"text-white text-xl font-semibold tracking-wider"}>
                      <div className={"text-gray-400 text-xl font-semibold tracking-wider"}>
                        <GameTimer timer={gameData.timer} />
                      </div>
                    </div>
                )}
              </div>
            )}
            <div className={`w-full ${isSpectate ? 'md:w-1/3' : 'md:w-1/2'} text-center md:text-right my-auto`}>
              <div className="text-xl text-orange-400 uppercase font-semibold tracking-wider">{((roundsTotal + 1) > matchData.modeData.modeConfig.ROUND_LIMIT) ? (props.t('other.gameover')) : `Round ${roundsTotal + 1}`}</div>
              <div className="text-xs text-gray-500 uppercase font-semibold tracking-wider">
                Best of {matchData.modeData.modeConfig.ROUND_LIMIT}
              </div>
            </div>
          </div>
        )}
      </>
    );
}

export default withTranslation()(MatchMode);
