import DesktopTop from "../DesktopTop";

function ComboTop() {
    return (
        <>
            <DesktopTop />
        </>
    )
}

export default ComboTop;