import { FC, lazy, Suspense, useState } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import { GlobalHotKeys } from 'react-hotkeys';
import Queue from './pages/Play/Queue';
import ExternalRedirect from './components/Uncategorized/ExternalRedirect';
import Match from './pages/Game/Match';
import LoadingScreen from "./components/Uncategorized/LoadingScreen";
import useConfig from "./hooks/useConfig";

const lazyHome = lazy(() => import('./pages/Home/Home'));
const lazyCustom = lazy(() => import('./pages/Custom/Custom'));
const lazyMatch = lazy(() => import('./pages/Game/Match'));
const lazyAbout = lazy(() => import('./pages/About/About'));
const lazyNews = lazy(() => import('./pages/News/News'));
const lazyTournaments = lazy(() => import('./pages/Tournaments/Tournaments'));
const lazyTournamentView = lazy(() => import('./pages/Tournaments/TournamentView'));
const lazyLeaderboards = lazy(() => import('./pages/Leaderboards/Leaderboards'));
const lazyProfile = lazy(() => import('./pages/Profile/Profile'));
const lazyBanned = lazy(() => import('./pages/Error/Banned'));
const lazyGuest = lazy(() => import('./pages/Error/Guest'));
const lazyError = lazy(() => import('./pages/Error/ErrorNotFound'));
const lazyShop = lazy(() => import('./pages/Shop/Shop'));
const lazyChallenges = lazy(() => import('./pages/Challenges/Challenges'));
const lazyPlayground = lazy(() => import('./components/Playground'));

const Router: FC = () => {
  const [ redirect, setRedirect ] = useState('');
  const { shortcutHome, shortcutExit, shortcutPlayRandom, shortcutPlayQuotes, shortcutPlayDictionary } = useConfig();

  const redirectTo = (uri: string, isRefresh?: boolean, isBack?: boolean) => {
    console.log('shortcut called', uri);
    if (isRefresh) return window.location.reload();
    if (isBack) return window.history.back();
    if (!isRefresh && !isBack) {
      setRedirect(uri);
      setTimeout(() => {
        setRedirect('');
      }, 1);
    }
  };

  const keyMap = {
    REDIRECT_PLAY: shortcutExit.toLowerCase(),
    REDIRECT_HOME: shortcutHome.toLowerCase(),
    REDIRECT_PLAY_RANDOM: shortcutPlayRandom.toLowerCase(),
    REDIRECT_PLAY_QUOTES: shortcutPlayQuotes.toLowerCase(),
    REDIRECT_PLAY_DICTIONARY: shortcutPlayDictionary.toLowerCase(),
  };

  const handlers = {
    REDIRECT_PLAY: () => redirectTo('/', false, false),
    REDIRECT_HOME: () => redirectTo('/', false, false),
    REDIRECT_PLAY_RANDOM: () => redirectTo('/play/random', false, false),
    REDIRECT_PLAY_QUOTES: () => redirectTo('/play/regular', false, false),
    REDIRECT_PLAY_DICTIONARY: () => redirectTo('/play/dictionary', false, false),
  };

  if (redirect)
    return <Redirect to={redirect} />;

  return (
    <>
      <Suspense fallback={<LoadingScreen />}>
        <GlobalHotKeys keyMap={keyMap} handlers={handlers} />
        <Switch>
          <Route exact path="/" component={lazyHome} />
          <Route path="/practice" component={lazyCustom} />
          <Route path="/game/random" component={() => <Match textType="random" />} />
          <Route path="/game/regular" component={() => <Match textType="regular" />} />
          <Route path="/game/dictionary" component={() => <Match textType="dictionary" />} />
          <Route path="/game" component={lazyMatch} />
          <Route path="/play/random" component={() => <Queue mode="random" />} />
          <Route path="/play/regular" component={() => <Queue mode="regular" />} />
          <Route path="/play/dictionary" component={() => <Queue mode="dictionary" />} />
          <Route path="/custom/:invite" component={lazyCustom} />
          <Route path="/custom" component={lazyCustom} />
          <Route path="/about/:page" component={lazyAbout} />
          <Route path="/about" component={lazyAbout} />
          <Route path="/news/:id" component={lazyNews} />
          <Route path="/news" component={lazyNews} />
          <Route path="/ladders/:id/:play" component={lazyTournamentView} />
          <Route path="/ladders/:id" component={lazyTournamentView} />
          <Route path="/ladders" component={lazyTournaments} />
          <Route path="/leaderboards/:type/:filter" component={lazyLeaderboards} />
          <Route path="/leaderboards/:type" component={lazyLeaderboards} />
          <Route path="/leaderboards" component={lazyLeaderboards} />
          <Route path="/challenges" component={lazyChallenges} />
          <Route path="/playground" component={lazyPlayground} />
          <Route path="/profile/*" component={lazyProfile} />
          <Route path="/shop" component={lazyShop} />
          <Route
            path="/submit"
            component={() => (
              <ExternalRedirect url="https://docs.google.com/forms/d/e/1FAIpQLScyTQWAOE3f6AmcvxrcjE3hKR2e8IrfLFao5Ta3U27k8Q_e5Q/viewform" />
            )}
          />
          <Route path="/banned" component={lazyBanned} />
          <Route path="/guest" component={lazyGuest} />
          <Route path="/merch" component={() => <ExternalRedirect url="https://teespring.com/stores/keymash" />} />
          <Route path="/discord" component={() => <ExternalRedirect url="https://discord.gg/df4paUq" />} />
          <Route path="/trello" component={() => <ExternalRedirect url="https://trello.com/b/Rlx2qSvq/keymash" />} />
          <Route path="/github" component={() => <ExternalRedirect url="https://github.com/Keyma-sh/game-tracker" />} />
          <Route path="/patreon" component={() => <ExternalRedirect url="https://patreon.com/KeymashGame" />} />
          <Route path="/twitter" component={() => <ExternalRedirect url="https://twitter.com/KeymashGame" />} />
          <Route component={lazyError} />
        </Switch>
      </Suspense>
    </>
  );
}

export default Router;
