
/*
 * NOTE: These do not contain padding/margins on the top and bottoms
 */
 
function SidebarSquare() {
    return (
        <div className={"pw bg-black bg-opacity-10 w-full mx-auto"}>
            <div id="responsive-top-square" className="pw-ph-leaderboard" data-pw-desk="med_rect_atf" data-pw-mobi="med_rect_atf" />
        </div>
    )
}

export default SidebarSquare;