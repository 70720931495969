import i18n from 'i18next';
import Backend from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';
import ConfigService from './services/ConfigService';

i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    fallbackLng: 'en',
    lng: ConfigService.getLocale(),
    debug: process.env.NODE_ENV === 'development',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    cache: {
        enabled: false
    },
    backend: {
        queryStringParams: { v: '0.0.7' },
    }
  });

export default i18n;
