import {FC, useState, useEffect, useRef, useCallback, ChangeEvent} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import axios, {CancelTokenSource} from "axios";
import Config from "../../Config";
import {faBell, faCog, faCommentDots, faSignIn, faSignOut, faTrash} from "@fortawesome/pro-solid-svg-icons";
import {faDiscord, faGithub, faGoogle} from "@fortawesome/free-brands-svg-icons";
import Socket from "../../utils/socket/Socket";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {Link, NavLink} from "react-router-dom";
import Notification from "../Uncategorized/Notification";
import {toast} from "react-toastify";
import {useChatContext} from "../../contexts/Chat.context";
import {usePlayerContext} from "../../contexts/Player.context";
import usePlayerToken from "../../hooks/usePlayerToken";
import {PlayerExtendedData, PlayerNotificationData} from "../../types.client.mongo";
import {ChatroomMessageProps} from "../../types.client.socket";
import PlayerAvatar from "../Player/PlayerAvatar";
import PlayerName from '../Player/PlayerName';
import moment from "moment";
import SettingsFrame from "../Settings/SettingsFrame";

interface IProps extends WithTranslation {
    isSidebar?: boolean;
}

const Userbar: FC<IProps> = (props) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>();

    const { playerToken } = usePlayerToken();
    const { sessionData, isGuest } = usePlayerContext();

    const [ socket, setSocket ] = useState<Socket | null>(null);
    const [ notificationsList, setNotificationsList ] = useState<PlayerNotificationData[]>([]);
    const [ notificationsCount, setNotificationsCount ] = useState(0);
    const [ notificationsLoaded, setNotificationsLoaded ] = useState(false);
    const [ toggleNotifications, setToggleNotifications ] = useState(false);
    const [ toggleSignIn, setToggleSignIn ] = useState(false);
    const [ isLoaded, setIsLoaded ] = useState(false);
    const { isSidebar, t } = props;

    /* --------- Settings */
    const [ showSettings, setShowSettings ] = useState<boolean>(false);

    /* --------- Chat */
    const { toggleChat, setToggleChat } = useChatContext();
    const [ inputText, setInputText ] = useState('');
    const [ chatData, setChatData ] = useState<ChatroomMessageProps[]>([]);
    const [ typingData, setTypingData ] = useState<string[]>([]);
    const [ participantsData, setParticipantsData ] = useState<PlayerExtendedData[]>([]);

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();

        if (window) {
            setSocket(new Socket(`${Config.gameServer.URL}${Config.gameServer.Port !== null ? `:${Config.gameServer.Port}` : ''}/account`, {
                transports: ['websocket', 'polling'],
            }));
            setIsLoaded(true);
            window.addEventListener('click', notificationToggleManual);
        }

        return () => {
            axiosCancelSource.current?.cancel();
            window.removeEventListener('click', notificationToggleManual);
        }
    }, [ ]);

    const logoutNow = () => {
        axios.get(`${Config.oauthUrl}/logout`, { withCredentials: true, })
            .then(() => window.location.reload())
            .catch(() => window.location.reload())
    }

    const notificationsEffect = useCallback(() => {
        if (socket && !toggleNotifications)
            socket?.emit('readNotifications', {});
    }, [ toggleNotifications, socket ]);

    useEffect(() => {
        notificationsEffect();
    }, [ notificationsEffect ]);

    useEffect(() => {
        socket?.onError(() => console.log('[Notifications] errored'));
        socket?.emit('joinNotifications', { playerToken });
        socket?.on('updateNotifications', async (data: { unread: number, data: PlayerNotificationData[] }) => {
            setNotificationsList(data.data);
            setNotificationsCount(data.unread);
            setNotificationsLoaded(true);
        });

        /* --------- Chat */
        socket?.on('getPlayers', (data: { results: PlayerExtendedData[] }) => setParticipantsData(data.results));

        socket?.emit('joinChatroom', {
            room: 'global',
            playerToken
        });

        socket?.on('sendTyping', (data: { name: string, isTyping: boolean }) => {
            setTypingData(typing => {
                let currentState = [ ...typing ];

                if (!currentState.includes(data.name) && data.isTyping)
                    currentState.push(data.name);

                if (currentState.includes(data.name) && !data.isTyping)
                    currentState = currentState.filter(name => name !== data.name);

                return currentState;
            });
        });

        socket?.on('sendMessage', (data: ChatroomMessageProps) => {
            setChatData(e => [ ...e, data ]);

            const overflowChatElement: HTMLElement | null = document.getElementById('globalChatOverflow');
            if (overflowChatElement) {
                console.log(overflowChatElement.scrollHeight, (overflowChatElement.offsetHeight + overflowChatElement.scrollTop));
                const isNotBottom = (overflowChatElement.scrollHeight - (overflowChatElement.offsetHeight + overflowChatElement.scrollTop)) >= 70;
                if (!isNotBottom) overflowChatElement.scrollTop = overflowChatElement.scrollHeight - overflowChatElement.clientHeight;
            }
        });
        socket?.on('tooFast', (data: { message: string }) => toast.error(data.message));
        return () => socket?.disconnect();
    }, [socket, playerToken]);

    /* --------- Chat */
    const chatOnChange = (e: ChangeEvent<HTMLInputElement>) => {
        socket?.emit('sendTyping', { isTyping: e.target.value.trim() !== "" });
        setInputText(e.target.value);
    }

    const chatOnKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            socket?.emit('sendMessage', { message: inputText });
            socket?.emit('sendTyping', { isTyping: false });
            setInputText('');
        }
    }

    const deleteNotifications = async () => socket?.emit('deleteNotifications', {});
    const notificationToggle = async (name: string, e: React.MouseEvent<HTMLAnchorElement>) => {
        if (name !== 'component.navbar.notifications')
            return false;

        e.preventDefault();
        if (toggleNotifications) {
            setToggleNotifications(false);
        } else
            setToggleNotifications(true);
    }

    const notificationToggleManual = (e: MouseEvent) => {
        if ((e.target as Element).classList.contains('notificationsWrapper'))
            setToggleNotifications(false);
    }

    const playerItems = [
        {
            title: 'component.navbar.logout',
            css: 'gray',
            icon: { name: faSignOut, css: 'text-gray-300' },
            target: '_self',
            route: ``,
            onClick: () => logoutNow(),
            isAuth: true
        },
        {
            title: 'component.navbar.settings',
            css: 'teal',
            icon: { name: faCog, css: 'text-teal-400' },
            target: '_self',
            route: '/settings',
            onClick: () => setShowSettings(true),
            isAuth: true,
        },
        {
            title: 'component.navbar.notifications',
            css: 'indigo',
            icon: { name: faBell, css: 'text-pink-700' },
            target: '_self',
            route: '/notification',
            isAuth: true,
        },
        {
            title: 'component.navbar.login',
            css: 'gray',
            icon: { name: faSignIn, css: 'text-gray-300' },
            target: '_self',
            route: `/login`,
            outbound: false,
            isAuth: false,
            submenu: [
                {
                    icon: { name: faDiscord, css: 'text-indigo-300' },
                    name: 'Discord',
                    route: `${Config.oauthUrl}/discord`
                },
                {
                    icon: { name: faGoogle, css: 'text-red-500' },
                    name: 'Google',
                    route: `${Config.oauthUrl}/google`
                },
                {
                    icon: { name: faGithub, css: 'text-white-100'},
                    name: 'GitHub',
                    route: `${Config.oauthUrl}/github`
                },
            ]
        },
    ];

    return (
        <>
            {!isGuest && (
                <>
                    <SettingsFrame isVisible={showSettings} onClose={() => setShowSettings(false)} />
                    <button type={"button"} onClick={() => setToggleChat(!toggleChat)} className={`fixed ${toggleChat ? 'bg-gray-775' : 'bg-gray-750'} hover:bg-gray-800 transition ease-in-out duration-300 focus:outline-none rounded-t right-3 bottom-0 py-2 px-6 text-sm font-semibold uppercase text-white`}>
                        <FontAwesomeIcon icon={faCommentDots} className={"mr-1"} /> Global Chat
                    </button>
                    <div className={`bg-gray-875 shadow-md border border-gray-700 fixed bottom-0 right-3 z-20 mb-12 w-11/12 lg:w-192 transform ${toggleChat ? '-translate-y-2' : 'pointer-events-none opacity-0 translate-y-0'} transition ease-in-out duration-300`}>
                        <div className={"flex"}>
                            <div className={"w-3/4"}>
                                <div id="globalChatOverflow" style={{ width: '100%', overflowY: 'auto', overflowX: 'hidden', height: '40vh' }} className={"p-4 pb-0"}>
                                    <div key={'globalMessageIntro'} className={"text-white py-0.5 break-all text-xs"}>{props.t('page.custom.chat')}</div>
                                    {chatData.map((item, key) => (
                                        <div key={key} className="text-white pb-1 text-sm lg:text-base break-all">
                                            <div className={"flex pt-2"}>
                                                <div className={"w-10 h-10"}>
                                                    <PlayerAvatar source={item.playerData.avatarSrc} color={item.playerData.cardBorder} />
                                                </div>
                                                <div className={"my-auto w-full pl-3"}>
                                                    <div className={"text-base font-semibold flex"}>
                                                        <PlayerName name={item.playerData.name} discriminator={item.playerData.discriminator} patreon={item.playerData.patreon} verified={item.playerData.verified} staff={item.playerData.staff} showDiscriminator />
                                                        <div className={"my-auto ml-2 text-xs text-gray-600 font-semibold"}>{moment.unix(item.posted).fromNow()}</div>
                                                    </div>
                                                    <div className={"text-gray-400 text-sm break-normal"}>{item.message}</div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={"pl-4 text-gray-400 font-semibold items-center h-3 pt-1 text-xs"}>
                                    {typingData.length !== 0 && (
                                        typingData.length === 1
                                            ? `${typingData[0]} is`
                                            : typingData.length === 2
                                            ? `${typingData[0]} and ${typingData[1]} are`
                                            : typingData.length === 3
                                                ? `${typingData[0]}, ${typingData[1]} and ${typingData[2]} are`
                                                : `Several people are`
                                    )}
                                    {typingData.length !== 0 && ' typing...'}
                                </div>
                                <div className={"flex mt-4"}>
                                    <input type={"text"} className={"form-settings"} onChange={chatOnChange} onKeyDown={chatOnKeyDown} maxLength={300} placeholder={"Press enter to send a message..."} value={inputText} />
                                </div>
                            </div>
                            <div className={"w-1/4 bg-gray-850"}>
                                <div className={"p-2 text-sm text-white text-center font-semibold"}>
                                    Online Now ({participantsData.length || 0})
                                </div>
                                <div style={{ overflowY: 'auto', height: '43vh' }}>
                                    {participantsData.map((item) => (
                                        <div
                                            key={item.playerId}
                                            className={"flex py-2 border-t border-gray-800 pl-3"}
                                            style={{
                                                borderColor: 'rgba(34, 40, 52)',
                                                backgroundSize: 'auto',
                                                backgroundPosition: 'center',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundImage: `url('${process.env.PUBLIC_URL}/playercards/${
                                                    item.cardImage ? item.cardImage : 'dotted_generic'
                                                }_transparent.png')`,
                                            }}
                                        >
                                            <div className={"w-10 h-10 pt-1"}>
                                                <PlayerAvatar source={item.avatarSrc} color={item.cardBorder} />
                                            </div>
                                            <div className={"my-auto w-full truncate pl-2"}>
                                                <div className={"text-lg font-semibold truncate"}>
                                                    <PlayerName name={item.name} discriminator={item.discriminator} verified={item.verified} patreon={item.patreon} staff={item.staff} showDiscriminator />
                                                </div>

                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
            {(isLoaded && toggleNotifications) && <div className={"notificationsWrapper fixed z-10 top-0 right-0 left-0 bottom-0 w-screen h-screen "} />}
            {(isLoaded && sessionData) && (
                <div className={`flex flex-wrap ${isSidebar ? 'flex-row-reverse justify-center gap-y-4 lg:gap-y-0' : 'justify-center lg:justify-end'}`}>
                    {(sessionData && isGuest) ? (
                        <>
                            <div className={`w-auto my-auto`}>
                                <div className="hidden lg:flex text-base text-white font-semibold tracking-wider">
                                    <div className={"w-8"}>
                                        <PlayerAvatar />
                                    </div>
                                    <div className={"w-auto my-auto pl-2"}>
                                        {sessionData.name}
                                    </div>
                                </div>
                            </div>
                            <div className={`w-auto relative ${isSidebar? 'pl-0 lg:pl-3' : 'pl-3'}`}>
                                <button type={"button"} onClick={() => setToggleSignIn(!toggleSignIn)} className="focus:outline-none text-base hover:bg-gray-775 rounded tracking-wider uppercase py-2 px-3 transition ease-in-out duration-200 text-white font-semibold">
                                    <FontAwesomeIcon icon={faSignIn} className="mr-2" />
                                    <span>{t('component.navbar.login')}</span>
                                </button>
                                {toggleSignIn && (
                                    <div className={"w-40 right-0 bg-gray-775 mt-1 absolute z-50"}>
                                        {playerItems[playerItems.length - 1].submenu?.map((item) => (
                                            <a key={item.route} href={item.route} className={"block transition ease-in-out duration-200 font-semibold p-2 hover:bg-white hover:bg-opacity-10 text-white text-base uppercase"}>
                                                <div className={"flex"}>
                                                    <div className={"w-auto pr-2"}>
                                                        <FontAwesomeIcon icon={item.icon.name} className={item.icon.css} />
                                                    </div>
                                                    <div className={"w-auto"}>
                                                        {item.name}
                                                    </div>
                                                </div>
                                            </a>
                                        ))}
                                    </div>
                                )}
                            </div>
                        </>
                    ) : (
                        <>
                            {playerItems.map(
                                item =>
                                    ((!item.isAuth && isGuest) || (!isGuest && item.isAuth)) && (
                                        <div key={item.route} className={`w-auto my-auto ${isSidebar? 'pl-0 lg:pl-3' : 'pl-3'}`}>
                                            {item.outbound ? (
                                                <a
                                                    key={item.route}
                                                    href={item.route}
                                                    className="text-xl rounded tracking-wider uppercase px-1 hover:text-orange-400 transition ease-in-out duration-300 text-white font-semibold"
                                                >
                                                    <FontAwesomeIcon icon={item.icon.name} />
                                                </a>
                                            ) : !item.onClick ? (
                                                <div className={"md:relative text-white"}>
                                                    <NavLink
                                                        key={item.route}
                                                        to={item.route}
                                                        onClick={(e) => notificationToggle(item.title, e)}
                                                        activeClassName={"text-orange-400"}
                                                        className={`nav-link text-xl rounded tracking-wider uppercase px-1 ${(toggleNotifications && item.title === 'component.navbar.notifications') ? 'text-orange-400' : ''} hover:text-orange-400 transition ease-in-out duration-300 font-semibold`}
                                                    >
                                                        <FontAwesomeIcon icon={item.icon.name} />
                                                        {item.title === 'component.navbar.notifications' && notificationsCount > 0 && (
                                                            <span style={{ fontSize: '.7rem' }} className="rounded px-1 text-white bg-red-600 ml-1">
                                                                {notificationsCount > 9 ? '9+' : notificationsCount}
                                                              </span>
                                                        )}
                                                    </NavLink>
                                                    {item.title === 'component.navbar.notifications' && (
                                                        <div className={`${toggleNotifications ? 'translate-y-0' : 'opacity-0 -translate-y-2 pointer-events-none'} transition ease-in-out duration-300 nav-dropdown absolute left-0 mt-4 rounded-lg right-0 md:left-auto w-full md:mx-0 md:w-80 lg:w-96 2xl:w-112 z-50`}>
                                                            <div className={"bg-gray-700 rounded-t-lg shadow flex py-2 px-4"}>
                                                                <div className={"w-auto mr-auto"}>
                                                                    <span className={"text-base  text-white uppercase font-semibold"}>
                                                                        {t('component.navbar.notifications')}
                                                                    </span>
                                                                </div>
                                                                <div className={"w-auto my-auto"}>
                                                                    <button className={"focus:outline-none text-base uppercase font-semibold text-orange-400 border-b hover:border-orange-400 border-transparent transition ease-in-out duration-300"} type={"button"} onClick={deleteNotifications}>
                                                                        <FontAwesomeIcon icon={faTrash} className={"mr-1"} /> Clear
                                                                    </button>
                                                                </div>
                                                            </div>
                                                            <div className={"h-96 bg-gray-750 overflow-y-scroll overflow-x-hidden"}>
                                                                {notificationsLoaded && notificationsList.map((row, index) => <Notification key={row._id || index} {...row} />)}
                                                            </div>
                                                        </div>
                                                    )}
                                                </div>
                                            ) : (
                                                <button type="button" key={item.title} onClick={item.onClick} className={`nav-link text-white hover:text-orange-400 text-xl rounded tracking-wider uppercase px-1 hover:text-orange-400 transition ease-in-out duration-300 font-semibold`}>
                                                    <FontAwesomeIcon icon={item.icon.name} />
                                                </button>
                                            )}
                                        </div>
                                    ),
                            )}
                            <Link to={`/profile/${sessionData.name}-${sessionData.discriminator}`} className={`flex w-auto my-auto ${isSidebar? 'pl-0 lg:pl-3' : 'pl-3'}`}>
                                <div className={'w-10 my-auto p-0.5 border-2 border-gray-500 border-opacity-50 hover:border-orange-400 hover:border-opacity-50 transition ease-in-out duration-200 rounded-full'}>
                                    <PlayerAvatar source={sessionData.avatarSrc} hideBorder />
                                </div>
                            </Link>
                        </>
                    )}
                </div>
            )}
        </>
    )
}

export default withTranslation()(Userbar);
