import {FC, useCallback, useEffect, useRef, useState} from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import axios, { CancelTokenSource } from 'axios';
import Config from '../../Config';
import TitleService from "../../services/TitleService";
import NewsItem from "../../components/News/NewsItem";
import Queue from "../Play/Queue";
import {
    NewsletterData,
    TournamentData,
    TwitchData
} from "../../types.client.mongo";
import Countdown from "../../components/Uncategorized/Countdown";
import Stream from "../../components/Twitch/Stream"
import SidebarDynamicLong from "../../components/Advertisement/SidebarDynamicLong";
import ComboBottom from "../../components/Advertisement/Combo/ComboBottom";
import LoadContent from "../../components/LoadContent";
import NewsService from "../../services/NewsService";
import LeaderboardPlayerMatch, {PlayerMatchExtendedData} from "../../components/Leaderboard/LeaderboardPlayerMatch";
import useConfig from "../../hooks/useConfig";
import SidebarSquare from "../../components/Advertisement/SidebarSquare";
import discordButton from "../../assets/buttons/discordbutton.webp";
import githubButton from "../../assets/buttons/githubbutton.webp";
import merchButton from "../../assets/buttons/merchbutton.webp";
import patreonButton from "../../assets/buttons/patreonbutton.webp";
import PlaywireContainer from '../../components/Advertisement/PlaywireContainer';
import ComboTop from '../../components/Advertisement/Combo/ComboTop';

const Home: FC<WithTranslation> = (props) => {
    const axiosCancelSource = useRef<CancelTokenSource | null>(null);
    const { world } = useConfig();

    const [ leaderboardsLoaded, setLeaderboardsLoaded ] = useState(false);
    const [ leaderboardsData, setLeaderboardsData ] = useState<PlayerMatchExtendedData[]>([]);
    const [ twitchLoaded, setTwitchLoaded ] = useState<boolean>(false);
    const [ twitchData, setTwitchData ] = useState<TwitchData[]>([]);
    const [ newsData, setNewsData ] = useState<NewsletterData[]>([]);
    const [ newsLoaded, setNewsLoaded ] = useState<boolean>(false);
    const [ laddersData, setLaddersData ] = useState<TournamentData[]>([]);
    const [ laddersLoaded, setLaddersLoaded ] = useState<boolean>(false);

    const getResults = useCallback(() => {
        axios.get(`${Config.apiUrl}/leaderboards/recent?worldId=${world}`, { withCredentials: true, cancelToken: axiosCancelSource.current?.token, })
            .then(response => {
                setLeaderboardsData(response.data);
                setLeaderboardsLoaded(true);
            })
            .catch(e => console.log(e));
    }, [ world ]);

    const getNews = () => {
        axios.get(`${Config.apiUrl}/newsletter/list?limit=2`, { withCredentials: true, cancelToken: axiosCancelSource.current?.token, })
            .then(response => {
                setNewsData(response.data.data);
                setNewsLoaded(true);
            })
            .catch(e => console.log(e));
    };

    const getTwitch = () => {
        axios.get(`${Config.gameUrl}/twitch?limit=6`, { cancelToken: axiosCancelSource.current?.token, })
            .then(response => {
                if (response.data) {
                    setTwitchData(response.data);
                    setTwitchLoaded(true);
                }
            })
            .catch(e => console.log(e));
    }

    const getTournaments = () => {
        axios
            .get(`${Config.apiUrl}/tournaments/list?locale=en&limit=3`, {
                cancelToken: axiosCancelSource.current?.token,
            })
            .then(response => {
                if (response.data) {
                    setLaddersData(response.data.data);
                    setLaddersLoaded(true);
                }
            })
            .catch(e => console.log(e));
    }

    useEffect(() => {
        axiosCancelSource.current = axios.CancelToken.source();
        TitleService.reset();

        getNews();
        getTournaments();
        getResults();
        getTwitch();

        return () => axiosCancelSource.current?.cancel();
    }, [ getResults ]);

    const getLatestNews = NewsService.getCookie();

    const socialItems = [
        {
            image: discordButton,
            href: 'https://discord.gg/df4paUq',
        },
        {
            image: patreonButton,
            href: 'https://patreon.com/KeymashGame',
        },
        {
            image: merchButton,
            href: 'https://store.keyma.sh/',
        },
        {
            image: githubButton,
            href: 'https://github.com/keyma-sh/game-tracker',
        }
    ]

    return (
        <div className={"container container-margin py-10"}>
            <LoadContent isLoaded={(newsLoaded && laddersLoaded && twitchLoaded && leaderboardsLoaded)} disableTransform>
                <h1 className={"h3 text-white pb-6 -mt-4 uppercase"}>
                    <span className={"uppercase font-bold"}>Keyma<span className={"text-orange-400"}>.</span>sh</span> - <span className={"opacity-80"}>The future of competitive typing</span>
                </h1>
                <Queue tournamentData={laddersData} />
            </LoadContent>
            <LoadContent isLoaded={(newsLoaded && laddersLoaded && twitchLoaded && leaderboardsLoaded)}>
                <PlaywireContainer enableBottomRail={true} />
                <div className={"flex flex-wrap xl:flex-row"}>
                    <div className={"w-full xl:w-3/4 xl:pr-12"}>
                        <ComboTop />
                        {/*
                        <a href={"https://store.keyma.sh"} rel={"noopener noreferrer"} target={"_blank"} className={"block hover:opacity-70 transition ease-in-out duration-300 mb-6"}>
                            <img src={merchBanner} alt={"Merch Banner"} className={"block mx-auto w-full h-auto border border-gray-700"} />
                        </a>
                        */}

                        {(leaderboardsLoaded && leaderboardsData.length !== 0) && (
                            <>
                                <h2 className="mb-3 py-1.5 w-76 text-center bg-gray-775 rounded-lg border-b-4 border-gray-800 text-lg md:text-xl lg:text-2xl font-bold text-white uppercase text-white">
                                    {props.t('page.home.matches_high')}
                                </h2>
                                <LeaderboardPlayerMatch data={leaderboardsData} skip={0} />
                                <Countdown minuteSeconds={60} onCountdownFinish={getResults} />
                                <ComboBottom />
                            </>
                        )}
                    </div>
                    <div className={"w-full xl:w-1/4"}>
                        <div className={"grid grid-cols-1 gap-8 pt-8 xl:pt-0"}>
                            <div>
                                <h2 className="mb-3 py-1.5 w-52 text-center bg-gray-775 rounded-lg border-b-4 border-gray-800 text-lg md:text-xl lg:text-2xl font-bold text-white uppercase text-white">
                                    {props.t('page.home.links')}
                                </h2>
                                <div className={"grid grid-cols-1 sm:grid-cols-2 xl:grid-cols-1 gap-3"}>
                                    {socialItems.map((item) => (
                                        <a key={item.href} href={item.href} target={"_blank"} rel={"noopener noreferrer"} className={`focus:outline-none hover:opacity-60 transition ease-in-out duration-300`}>
                                            <img className={"w-full h-auto"} src={item.image} alt={`Socials`} />
                                        </a>
                                    ))}
                                </div>
                            </div>

                            <SidebarSquare />

                            {(newsLoaded && newsData.length !== 0) && (
                                <div>
                                    <h2 className="mb-3 py-1.5 w-56 text-center bg-gray-775 rounded-lg border-b-4 border-gray-800 text-lg md:text-xl lg:text-2xl font-bold text-white uppercase text-white">
                                        {props.t('page.queue.titles.latestNews')}
                                    </h2>
                                    <div className={"grid grid-cols-1 gap-4"}>
                                        {newsData.map(item => <NewsItem key={item.slug} {...item} showUnread={item.increment > getLatestNews} isBig /> )}
                                    </div>
                                </div>
                            )}

                            {(twitchLoaded && twitchData.length !== 0) && (
                                <div>
                                    <h2 className="mb-3 h3 uppercase text-white">
                                        {props.t('page.queue.titles.streams')}
                                    </h2>
                                    {twitchData.map(row => <Stream isList key={row.name} {...row} />)}
                                </div>
                            )}

                            <SidebarDynamicLong />
                        </div>
                    </div>
                </div>
            </LoadContent>
        </div>
    );
}

export default withTranslation()(Home);
