import { StrictMode } from 'react';
import ReactDOM from 'react-dom';
import './scss/index.scss';
import 'react-toastify/dist/ReactToastify.css';
import { App } from './App';
import './i18n';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

ReactDOM.render(
  <StrictMode>
  <App />
  </StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more About service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

reportWebVitals(console.log);