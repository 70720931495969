import {FC} from 'react';
import truncate from "truncate";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBadgeCheck, faDollarSign, faShieldAlt} from "@fortawesome/pro-solid-svg-icons";

interface IProps {
    name: string;
    discriminator: string;
    verified: number;
    patreon: number;
    staff: number;

    showDiscriminator?: boolean;
    useSmall?: boolean;
    useBig?: boolean;
}

const PlayerName:FC<IProps> = (props) => {
    const { name, discriminator, verified, patreon, staff, showDiscriminator, useSmall, useBig } = props;

    return (
        <div className={`flex space-x-2  ${useSmall ? 'text-base' : (useBig ? 'text-2xl' : 'text-lg')}`}>
            <div className={`truncate font-semibold text-white`}>
                {truncate(name, 14)}
                {showDiscriminator && <span className={"text-gray-500"}>#{discriminator}</span>}
            </div>
            {verified === 1 && <FontAwesomeIcon className="my-auto block text-orange-400" icon={faBadgeCheck} />}
            {patreon === 1 && (
                <div className="my-auto" title="Patreon Supporter">
                    <FontAwesomeIcon className="text-orange-400" icon={faDollarSign} />
                </div>
            )}
            {staff === 1 && (
                <div className="my-auto" title="Staff">
                    <FontAwesomeIcon className="text-orange-400" icon={faShieldAlt} />
                </div>
            )}
        </div>
    )
}

export default PlayerName;