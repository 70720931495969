import { FC } from 'react';
import { withTranslation, WithTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinnerThird } from '@fortawesome/pro-duotone-svg-icons';

interface IProps extends WithTranslation {
  isReconnecting: boolean;
  isConnectionLost: boolean;
}

const MatchToast: FC<IProps> = (props) => {
    const { isReconnecting, isConnectionLost } = props;

    return (
        <>
            {(isReconnecting || isConnectionLost) && (
            <div className="fixed z-50 top-0 left-0 right-0">
              <div className="w-64 pt-20 mx-auto">
                <div className="p-3 bg-blue-500 border-blue-900 rounded border-b-4 text-lg text-center text-white font-semibold tracking-wide">
                  <FontAwesomeIcon icon={faSpinnerThird} spin className="mr-4" />
                  {isReconnecting && props.t('page.match.reconnect')}
                  {isConnectionLost && props.t('page.match.connect_lost')}
                </div>
              </div>
            </div>
          )}
        </>
    );
}

export default withTranslation()(MatchToast);
