import io from 'socket.io-client';

type Callback<T> = (data: T) => void;

class Socket {
  private client: SocketIOClient.Socket;

  constructor(uri?: string, opts?: SocketIOClient.ConnectOpts) {
    this.client = io.connect(uri || 'http://localhost', opts || {});
  }

  public on<T>(name: string, callback: Callback<T>): void {
    this.client.on(name, callback);
  }

  public emit(name: string, data: unknown): void {
    this.client.emit(name, data);
  }

  public onConnect(callback: () => void): void {
    this.client.on('connect', callback);
  }

  public onConnectLost(callback: () => void): void {
    this.client.on('connect_timeout', callback);
  }

  public onReconnect(callback: () => void): void {
    this.client.on('reconnect', callback);
  }

  public onReconnecting(callback: () => void): void {
    this.client.on('reconnecting', callback);
  }

  public onConnectSaving(callback: () => void): void {
    this.client.on('reconnect_attempt', callback);
  }

  public onConnectSaved(callback: () => void): void {
    this.client.on('reconnect', callback);
  }

  public onConnectNotSaved(callback: () => void): void {
    this.client.on('reconnect_failed', callback);
  }

  public onError(callback: Callback<Record<string, unknown>>): void {
    this.client.on('error', callback);
  }

  public onDisconnect(callback: Callback<Record<string, unknown>>): void {
    this.client.on('disconnect', callback);
  }

  public disconnect(): void {
    this.client.disconnect();
  }

  public close(): void {
    this.client.close();
  }
}

export default Socket;
