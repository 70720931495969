import DesktopFooter from "../DesktopFooter";

function ComboBottom() {
    return (
        <>
            <DesktopFooter />
        </>
    )
}

export default ComboBottom;