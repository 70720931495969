import { FC, useState } from 'react';
import { useMediaQuery } from 'react-responsive';
import { withTranslation, WithTranslation } from 'react-i18next';
import logo from '../../assets/logo_svg.svg';
import {NavLink} from "react-router-dom";
import {faDiscord, faFacebookF, faGithub, faPatreon, faReddit, faTwitter} from "@fortawesome/free-brands-svg-icons";
import Userbar from "./Userbar";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    faGamepadAlt,
    faListAlt,
    faNewspaper,
    faStoreAlt,
    faTrophy
} from "@fortawesome/pro-solid-svg-icons";
import {usePlayerContext} from "../../contexts/Player.context";

const Levelbar:FC<WithTranslation> = (props) => {

    const { sessionData, isGuest } = usePlayerContext();
    const [ toggleSitebar, setToggleSitebar ] = useState(false);
    const isSmallerDevice = useMediaQuery({ query: `(max-width: 1024px)` });
    const { t } = props;

    const links = [
        {
            name: 'component.bottombar.about',
            link: '/about/us',
            external: false,
        },
        {
            name: 'component.bottombar.contribute',
            link: '/about/contribute',
            external: false,
        },
        {
            name: 'component.bottombar.tos',
            link: '/about/tos',
            external: false,
        },
        {
            name: 'component.bottombar.privacy',
            link: '/about/privacy',
            external: false,
        },
        {
            name: 'component.bottombar.troubleshooting',
            link: '/about/troubleshooting',
            external: false,
        },
        {
            name: 'component.bottombar.faqM',
            link: '/about/faq',
            external: false,
        },
        {
            name: 'component.bottombar.issueTracker',
            link: '/github',
            external: true,
        },
    ];

    const socials = [
        {
            name: 'Discord',
            icon: faDiscord,
            color: 'hover:text-orange-700',
            link: 'https://discord.gg/df4paUq',
        },
        {
            name: 'Twitter',
            icon: faTwitter,
            color: 'hover:text-blue-400',
            link: 'https://twitter.com/KeymashGame',
        },
        {
            name: 'Facebook',
            icon: faFacebookF,
            color: 'hover:text-blue-600',
            link: 'https://facebook.com/KeymashGame',
        },
        {
            name: 'Reddit',
            icon: faReddit,
            color: 'hover:text-orange-700',
            link: 'https://reddit.com/r/Keymash',
        },
        {
            name: 'Patreon',
            icon: faPatreon,
            color: 'hover:text-red-600',
            link: 'https://patreon.com/KeymashGame',
        },
        {
            name: 'Merch',
            icon: faStoreAlt,
            color: 'hover:text-orange-700',
            link: 'https://keymash.creator-spring.com/',
        },
        {
            name: 'GitHub',
            icon: faGithub,
            color: 'hover:text-gray-400',
            link: 'https://github.com/Keyma-sh/game-tracker',
        }
    ];

    const navCSS = `desktopNav-item`;
    const activeCSS = `levelbar-active`;

    const mobileNavCSS = `mobileNav-item`;
    const mobileActiveCSS = `levelbar-active`;

    return (
        isSmallerDevice ? (
            <>
                {/* Mobile */}
                <div className={"lg:hidden fixed top-0 left-0 right-0 z-50 bg-gray-775 shadow"}>
                    <div className={"flex py-1 px-2 justify-between"}>
                        <div className={"w-auto pt-1 my-auto"}>
                            <button type={"button"} onClick={() => setToggleSitebar(!toggleSitebar)} className={"w-8 my-auto focus:outline-none"}>
                                <img src={logo} alt={"Logo"} className={"w-full h-auto"} />
                            </button>
                        </div>
                        <div className={"w-auto"}>
                            <Userbar />
                        </div>
                    </div>
                </div>

                <div className={"lg:hidden fixed grid grid-cols-4 bottom-0 left-0 right-0 z-50 bg-gradient-to-r from-gray-850 to-gray-775 border-t border-gray-750 shadow"}>
                    <NavLink exact to={"/"} className={mobileNavCSS} activeClassName={mobileActiveCSS}>
                        <div className={"text-center text-xxs"}>
                            <FontAwesomeIcon icon={faGamepadAlt} className={"mb-2 text-2xl"} />
                            <div>{t('component.navbar.play')}</div>
                        </div>
                    </NavLink>
                    <NavLink to={"/news"} className={mobileNavCSS} activeClassName={mobileActiveCSS}>
                        <div className={"text-center text-xxs"}>
                            <FontAwesomeIcon icon={faNewspaper} className={"mb-2 text-2xl"} />
                            <div>{t('component.navbar.news')}</div>
                        </div>
                    </NavLink>
                    <NavLink to={"/leaderboards"} className={mobileNavCSS} activeClassName={mobileActiveCSS}>
                        <div className={"text-center text-xxs"}>
                            <FontAwesomeIcon icon={faListAlt} className={"mb-2 text-2xl"} />
                            <div>{t('component.navbar.leaders')}</div>
                        </div>
                    </NavLink>
                    <NavLink to={"/ladders"} className={mobileNavCSS} activeClassName={mobileActiveCSS}>
                        <div className={"text-center text-xxs"}>
                            <FontAwesomeIcon icon={faTrophy} className={"mb-2 text-2xl"} />
                            <div>{t('component.navbar.tournaments')}</div>
                        </div>
                    </NavLink>
                </div>
            </>
        ) : (
            <>
                <div className={"hidden lg:block fixed top-0 left-0 right-0 z-50 bg-gray-775 bg-opacity-100 shadow"}>
                    <div className={"container flex"}>
                        <div className={"w-16 flex bg-gray-800 bg-opacity-80 justify-center"}>
                            <button type={"button"} onClick={() => setToggleSitebar(!toggleSitebar)} className={"w-8 my-auto hover:opacity-50 transition ease-in-out duration-300 focus:outline-none"}>
                                <img src={logo} alt={"Logo"} className={"w-full h-auto"} />
                            </button>
                        </div>
                        <NavLink exact to={"/"} className={navCSS} activeClassName={activeCSS}>{t('component.navbar.play')}</NavLink>
                        {isGuest && <NavLink to={"/about/us"} className={navCSS} activeClassName={activeCSS}>{t('component.bottombar.about')}</NavLink>}
                        <NavLink to={"/news"} className={navCSS} activeClassName={activeCSS}>{t('component.navbar.news')}</NavLink>
                        {!isGuest && <NavLink to={"/challenges"} className={navCSS} activeClassName={activeCSS}>{t('component.navbar.challenges')}</NavLink>}
                        <NavLink to={"/leaderboards"} className={navCSS} activeClassName={activeCSS}>{t('component.navbar.leaders')}</NavLink>
                        <NavLink to={"/ladders"} className={navCSS} activeClassName={activeCSS}>{t('component.navbar.tournaments')}</NavLink>
                        {!isGuest && <NavLink to={"/shop"} className={navCSS} activeClassName={activeCSS}>{t('component.navbar.shop')}</NavLink>}
                        {sessionData && (
                            <div className={"ml-auto my-auto w-80"} >
                                <div className={"flex justify-end py-1"}>
                                    <Userbar />
                                </div>
                            </div>
                        )}
                    </div>
                </div>

                <div style={{ left: `${!toggleSitebar ? '-80' : '0'}%` }} className={"w-44 z-20 mt-12 fixed top-0 left-0 bottom-0 h-screen bg-gray-900 transition-all ease-in-out duration-300"}>
                    {links.map((item) => <NavLink key={item.link} to={item.link} activeClassName={"bg-gray-825"} className={"block hover:bg-gray-825 transition ease-in-out duration-300 p-3 text-sm uppercase font-semibold text-white tracking-tight"}>{t(item.name)}</NavLink>)}
                    <div className={"border-t border-gray-800"} />
                    {socials.map((item) => (
                        <a key={item.link} href={item.link} target="_blank" rel="noreferrer" className={"flex hover:bg-gray-825 transition ease-in-out duration-300 p-3 text-sm uppercase font-semibold text-white tracking-tight"}>
                            <div className={"w-12"}>
                                <FontAwesomeIcon icon={item.icon} />
                            </div>
                            <div className={"w-auto"}>
                                {item.name}
                            </div>
                        </a>
                    ))}

                </div>
            </>
        )
    );
}

export default withTranslation()(Levelbar);
