import Cookie from 'universal-cookie';
import Config from "../Config";

class NewsService extends Cookie {

    public storageJSON: number = (this.get('latestNews') ? parseInt(this.get('latestNews'), 10) : 0);

    setCookie(id: number) {
        this.set('latestNews', id, {
            domain: Config.cookieUrl,
            expires: new Date(2147483647 * 1000)
        });
        this.storageJSON = id;
    }

    getCookie() {
        return this.storageJSON;
    }

}

export default new NewsService();
