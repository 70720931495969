import Config from '../Config';

class TitleService {

    set(name: string) {
        const titleString = `${name} - ${Config.NAME}`;

        if (document && document.title)
            document.title = titleString;

        const ogTitle = document.querySelector('meta[property="og:title"]');
        if (ogTitle)
            ogTitle.setAttribute("content", titleString);

        const twitterTitle = document.querySelector('meta[name="twitter:title"]');
        if (twitterTitle)
            twitterTitle.setAttribute("content", titleString);

        return true;
    }

    reset() {
        const titleString = `${Config.NAME} - ${Config.SLOGAN}`;

        if (document && document.title)
            document.title = titleString;

        const ogTitle = document.querySelector('meta[property="og:title"]');
        if (ogTitle)
            ogTitle.setAttribute("content", titleString);

        const twitterTitle = document.querySelector('meta[name="twitter:title"]');
        if (twitterTitle)
            twitterTitle.setAttribute("content", titleString);

        return true;
    }
}

export default new TitleService();
