import {FC, MouseEventHandler} from 'react';
import { withTranslation, WithTranslation, Trans } from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronDoubleRight, faTimes} from "@fortawesome/pro-solid-svg-icons";

interface IProps extends WithTranslation {
    toggle: MouseEventHandler<HTMLButtonElement>;
}

const RankedModal: FC<IProps> = (props) => {
    const ranks = [
        {
            name: 'Bronze',
            sr: '1 - 850',
        },
        {
            name: 'Silver',
            sr: '850 - 1299',
        },
        {
            name: 'Gold',
            sr: '1300 - 1749',
        },
        {
            name: 'Diamond',
            sr: '1750 - 2199',
        },
        {
            name: 'Master',
            sr: '2200 - 2399',
        },
        {
            name: 'Grandmaster',
            sr: '2400 +',
        },
    ];

    return (
        <div className="z-50 fixed bottom-0 left-0 right-0 top-0 w-full h-screen bg-black bg-opacity-80 rounded">
            <div className="flex h-screen">
                <div className="relative m-auto w-full lg:w-10/12 xl:w-8/12 2xl:w-6/12">
                    <button type="button" className="z-50 absolute top-0 right-0 mt-4 mr-4 hover:opacity-70 transition ease-in-out duration-300" onClick={props.toggle}>
                        <FontAwesomeIcon className="text-2xl text-gray-300" icon={faTimes} />
                    </button>
                    <div className="flex flex-wrap shadow-lg">
                        <div className="w-full lg:w-2/3 bg-gray-900 p-6 rounded-l">
                            <div>
                                <div className="text-white text-lg uppercase font-bold tracking-wider border-b border-gray-800 pb-2 mb-2">{props.t('page.queue.howToPlayGuide.1_title')}</div>
                                <div className="text-gray-300 text-sm tracking-wider">
                                    <Trans i18nKey="page.queue.howToPlayGuide.1_description">
                                        In order to play Ranked Matchmaking you must be at least <span className="font-semibold">Level 10</span>. You will be placed through a calculation stage where you will not see your rank until you have finished <span className="font-semibold">10 matches</span>. This will ensure the accuracy of your skill level when you get matched against other players.
                                    </Trans>
                                </div>
                            </div>

                            <div className="pt-6">
                                <div className="text-white text-lg uppercase font-bold tracking-wider border-b border-gray-800 pb-2 mb-2">What is Ranked?</div>
                                <div className="text-gray-300 text-sm tracking-wider">
                                    {props.t('page.queue.howToPlayGuide.2_description')}
                                </div>
                            </div>

                            <div className="pt-6">
                                <div className="text-white text-lg uppercase font-bold tracking-wider border-b border-gray-800 pb-2 mb-2">How is SR determined?</div>
                                <div className="text-gray-300 text-sm tracking-wider">
                                    {props.t('page.queue.howToPlayGuide.3_description')}
                                </div>
                            </div>

                            <div className="pt-6">
                                <div className="text-white text-lg uppercase font-bold tracking-wider border-b border-gray-800 pb-2 mb-2">Can we quit mid-game?</div>
                                <div className="text-gray-300 text-sm tracking-wider">
                                    <Trans i18nKey="page.queue.howToPlayGuide.4_description">Due to the competitive nature it is extremely discouraged to quit a game. If you quit before you finish playing your ranked game then <span className="text-red-400">20 points</span> will be deducted from your skill rating. Additionally if your opponent finishes the game you will suffer the loss as if you had completed the match.</Trans>
                                </div>
                            </div>

                            <div className="pt-6">
                                <button onClick={props.toggle} className="text-base tracking-wider font-semibold uppercase text-orange-400">
                                    {props.t('button.letsgo')} <FontAwesomeIcon icon={faChevronDoubleRight} />
                                </button>
                            </div>
                        </div>
                        <div className="w-full lg:w-1/3 bg-gray-875 p-6 rounded-r">
                            <div className="text-white text-xl uppercase font-semibold tracking-wider border-b border-gray-800 pb-3 mb-3">Ranks</div>
                            {ranks.map(item => (
                                <div key={item.name} className="flex text-white py-3">
                                    <div className="w-10 my-auto">
                                        <img src={`${process.env.PUBLIC_URL}/ranks/${item.name.toLowerCase()}.png`} className="w-full h-auto" alt="skillrank" />
                                    </div>
                                    <div className="pl-2 w-full">
                                        <div className="text-xl font-semibold uppercase tracking-wider">{item.name}</div>
                                        <div className="mt-1 text-orange-400 font-semibold tracking-wider">{item.sr}</div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withTranslation()(RankedModal);
