import {FC, ReactElement} from 'react';
import LoadingScreen from "./Uncategorized/LoadingScreen";

interface LoadContentProps {
    isLoaded: boolean;
    isPartial?: boolean;
    children: ReactElement | JSX.Element[] | Element[];
    disableTransform?: boolean;
}

const LoadContent:FC<LoadContentProps> = (props) => {
    return (
        <>
            <div className={`${props.isLoaded ? `${!props.disableTransform && 'translate-y-0'} opacity-100` : `${!props.disableTransform && 'translate-y-1'} opacity-0`} transition ease-in-out duration-200`}>
                {props.isLoaded ? props.children : ''}
            </div>
            {!props.isLoaded ? <LoadingScreen isPartial={props.isPartial} /> : ''}
        </>
    )
}

export default LoadContent;