import { FC } from 'react';

interface IProps {
  message: string;
}

const MatchNotice: FC<IProps> = (props) => {
    const { message } = props;
    return (
      <div className="game--content py-8">
        <div className="text-lg text-white font-semibold text-center py-16">{message}</div>
      </div>
    );
}

export default MatchNotice;
