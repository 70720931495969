import {FC, useState} from 'react';
import DebugService from "../../services/DebugService";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faBug, faTimesCircle} from "@fortawesome/pro-solid-svg-icons";
import {toast} from "react-toastify";
import { useTranslation } from 'react-i18next';

interface IProps {
    onClickOpen: any;
    onClickEnd: any;
}

const MatchBug: FC<IProps> = (props) => {
    const [ overlay, setOverlay ] = useState(0);
    const { t } = useTranslation();

    const debugLog = DebugService.get();
    let debugString = "";

    debugLog.map((item: string) => debugString += `${item}\n`);

    const clickOpen = () => {
        props.onClickOpen();

        setOverlay(overlay + 1);
    }

    const clickCopy = () => {
        setTimeout(() => {
            const getTextarea : any = document.getElementById('copyPasteDebug');
            if (getTextarea) {
                getTextarea.select();
                document.execCommand('copy');
            }
            toast.success('Copied to Clipboard');
        }, 50);
    }

    const clickExit = () => {
        props.onClickEnd();
        setOverlay(overlay - 1);
    }

    return (
        <div>
            <button type={"button"} onClick={clickOpen} className={"absolute bottom-0 left-0 w-16 bg-black bg-opacity-70 hover:bg-black hover:bg-opacity-40 transition ease-in-out duration-300 rounded-t-2xl text-center py-3 ml-4 z-50"}>
                <FontAwesomeIcon icon={faBug} className={"text-2xl text-white text-center mt-1"} />
            </button>
            {overlay === 1 && (
                <div className={"fixed top-0 left-0 right-0 bottom-0 flex h-screen bg-black bg-opacity-70"} style={{ zIndex: 100 }}>
                    <div className={"m-auto w-full"}>
                        <div className={"w-4/5 md:w-4/6 lg:w-3/5 xl:w-3/6 2xl:w-2/5 mx-auto p-8 rounded shadow bg-gray-800 relative"}>
                            <button type={"button"} className={"absolute top-0 right-0 mt-5 mr-5 text-white text-opacity-70 hover:text-white hover:text-opacity-40 transition ease-in-out duration-300"} onClick={clickExit}>
                                <FontAwesomeIcon icon={faTimesCircle} className={"text-xl"} />
                            </button>
                            <div className={""}>
                                <div className={"text-gray-300"}>
                                    <div className={"text-2xl font-semibold tracking-wider text-white"}>{t('other.debug.title')}</div>
                                    <div className={"py-2"}>
                                        {t('other.debug.message')}
                                        <ul className={"pl-8 pt-2 list-disc"}>
                                            <li>{t('other.debug.list.0')}</li>
                                            <li>{t('other.debug.list.1')}</li>
                                            <li>{t('other.debug.list.2')}</li>
                                            <li>{t('other.debug.list.3')}</li>
                                        </ul>
                                    </div>
                                </div>
                                <button type={"button"} className={"btn btn--blue mt-2"} onClick={clickCopy}>Copy to Clipboard</button>
                            </div>
                            <textarea id={"copyPasteDebug"} rows={10} className={"absolute opacity-0"} defaultValue={debugString} readOnly={true} />
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default MatchBug;
